import * as React from "react";
import { FC, ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";
import { Columns, Column, Title } from "bloomer";

import Page from "../templates/Page";

const NotFound: FC<RouteComponentProps> = (): ReactElement => {
  return (
    <Page title="404 Not Found " className="NotFound">
      Not found
    </Page>
  );
};

export default NotFound;
