import * as React from "react";
import { FC, ReactElement } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { atom, useAtom } from "jotai";

import { GET_PRODUCTS_BY_COLLECTION } from "../../apollo/queries/Products";
import {
  CREATE_CHECKOUT_CART,
  ADD_VARIANT_TO_CART,
} from "../../apollo/mutation/Cart";
import {
  customerCheckout,
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
} from "../../apollo/mutation/Checkout";
import Page from "../templates/Page";
import { cookies } from "../../lib/cookie";
import idr from "../../utils/idr";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import { useLocation } from "@reach/router";
import qs from "qs";

const queryParams = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return queryParams;
};

import Logo from "../../assets/logo.svg";
import SantiLogo from "../../assets/santi-logo.svg";
// import Search from "../../assets/search.svg";
import Cart from "../../assets/cart.svg";
import Espresso from "../../assets/espresso.svg";
import Filter from "../../assets/filter.svg";
import Goods from "../../assets/goods.svg";
import Misc from "../../assets/misc.svg";
import Back from "../../assets/back.svg";
import CloseCircle from "../../assets/close-circle.svg";

interface ProductProps {
  node: {
    title: string;
    description: string;
    descriptionHtml: string;
    images: {
      edges: ImageInterface[];
    };
    variants: {
      edges: VariantInterface[];
    };
  };
}

interface ImageInterface {
  node: {
    src: string;
  };
}

interface VariantInterface {
  node: {
    title: string;
    selectedOptions?: VarianOptions[];
    image: { src: string }[];
    price: PriceInterface;
  };
}

interface VarianOptions {
  name: string;
  value: string;
}

interface ProductsProps {
  products: ProductProps[];
}

interface ItemsInterface {
  title?: string;
  variant: VariantInterface[];
  quantity?: number;
  node: any;
}

interface PriceInterface {
  currencyCode: string;
  amount: number;
}
interface CartInterface {
  id: string;
  subtotalPrice: PriceInterface;
  totalTax: PriceInterface;
  totalPrice: PriceInterface;
  webUrl: string;
  lineItems?: {
    edges?: ItemsInterface[];
  };
}

export const currentCategoryAtom = atom<string>("homepage-wholesale");

const Categories = () => {
  const CATEGORIES = [
    "Espresso-Wholesale",
    "Filter-Wholesale",
    "Merchandise",
    "Misc",
  ];

  const [currentCategory, setCurrentCategory] = useAtom(currentCategoryAtom);

  const categoryIcons = (category: string) => {
    const sanitizedCategory = category.toLowerCase();
    switch (sanitizedCategory) {
      case "espresso-wholesale":
        return <Espresso />;
        break;
      case "filter-wholesale":
        return <Filter />;
        break;
      case "merchandise":
        return <Goods />;
        break;
      case "misc":
        return <Misc />;
        break;
    }
  };

  const handleCurrentCategory = (category: string): void => {
    setCurrentCategory(category);
  };

  return (
    <section className="categories">
      {CATEGORIES.map((category, key) => {
        return (
          <article
            key={key}
            className={`category ${
              currentCategory === category ? "category--active" : ""
            }`}
            onClick={() => handleCurrentCategory(category)}
          >
            <span className="category__icon">{categoryIcons(category)}</span>
            <span className="category__title">
              {category === "Merchandise" ? "Goods" : category.split("-")[0]}
            </span>
          </article>
        );
      })}
    </section>
  );
};

const Loading = ({ color }: { color?: string }) => (
  <div className="Loading" style={{ color: color }}>
    Loading
    <div className="Loading-Dot one">.</div>
    <div className="Loading-Dot two">.</div>
    <div className="Loading-Dot three">.</div>
  </div>
);

const Home: React.FC<RouteComponentProps> = () => {
  return (
    <div className="CustomerAuth">
      <a className="CustomerAuth__back" href="#backToApp">
        <Back />
      </a>
      <div className="CustomerAuth__logo">
        <SantiLogo />
      </div>
      <div className="CustomerAuth__body">
        <h2 className="CustomerAuth__heading">
          Coffee?
          <span>Welcome Back</span>
        </h2>
        <a
          href="https://shop.sabarmenanti.coffee/auth/login"
          className="CustomerAuth__submit button"
        >
          Log in
        </a>
      </div>
    </div>
  );
};

export default Home;
