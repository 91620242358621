import * as React from "react";
import { render } from "react-dom";
import { ApolloProvider } from "react-apollo";
import ApiClient from "./apollo/ApiClient";
import { Provider} from "jotai";
import Routes from "./Routes";

render(
    <Provider>
      <ApolloProvider client={ApiClient}>
        <Routes />
      </ApolloProvider>
    </Provider>,
  document.getElementById("app")
);
