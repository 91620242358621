import * as React from "react";
import { ReactNode, ReactElement } from "react";
import { NavbarStart, NavbarItem } from "bloomer";

import Navigation from "../Navigation";
import Section from "../Section";
interface Props {
  title: string;
  className: string;
  children: ReactNode;
}

const Page = ({ title, className, children }: Props): ReactElement<Props> => {
  return (
    <div className={className}>
      <Section title={title}>{children}</Section>
    </div>
  );
};

export default Page;
